.content-section {
    width: 95%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
}

.image__container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.content__image {
    border: 8px solid white;
    border-radius: 15px;
}

.content__container {
    z-index: 9;
    background-color: #2d7254;
    padding: 10px;
    border-radius: 15px;
}

.content {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.3;
    letter-spacing: .5px;
}

.title__container {
    background-color: #000103;
    font-family: 'Source Code Pro', monospace;
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px 10px;
    border-bottom: 5px solid #cec878;
}

.title__container h3,
.title__container p {
    margin: 0;
}

.content__title {
    font-size: 22px;
}

.links__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 8px;
    flex-direction: column;
}

.links__button {
    background-color: #000103;
    padding: 8px 16px;
    color: white;
    font-family: 'Source Code Pro', monospace;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
    white-space: nowrap;
}

.links__button:hover {
    -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
            transform: translateY(-3px);
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
}

@media (min-width: 750px) {
    .content__container {
        padding: 25px;
    }
    
    .content {
        font-size: 20px;
    }

    .title__container {
        padding: 8px 10px;
        font-size: 18px;
    }

    .content__title {
        font-size: 26px;
    }

    .links__button {
        font-size: 20px;
    }
}

@media (min-width: 1020px) {
    .content-section {
        width: 60%;
    }

    .content__container {
        padding: 30px;
    }
    
    .content {
        font-size: 16px;
    }

    .title__container {
        padding: 8px 10px;
        font-size: 18px;
    }

    .content__title {
        font-size: 24px;
    }

    .links__button {
        font-size: 18px;
    }
}

@media (min-width: 1200px) {
    .content-section {
        position: relative;
        width: 100%;
    }
    
    .image__container {
        position: absolute;
        top: 10%;
        left: 30%;
        transform: translateX(-50%);
    }
    
    .content__image {
        width: 45vw;
        max-width: 650px;
    }
    
    .content__container {
        width: 52vw;
        position: relative;
        top: 5%;
        left: 40%;
        z-index: 9;
        padding: 15px;
    }

    .content {
        font-size: 18px;
    }
    
    .title__container {
        width: 60%;
        position: relative;
        left: -10%;
        margin: 0;
        padding: 10px 20px;
        font-size: 16px;
    }

    .content__title {
        font-size: 20px;
    }

    .links__container {
        flex-direction: row;
    }

    .links__button {
        font-size: 16px;
    }
}

@media (min-width: 1400px) {
    .content__container {
        max-width: 45%;
    }

    .content {
        font-size: 18px;
    }
    
    .title__container {
        font-size: 18px;
    }

    .content__title {
        font-size: 22px;
    }

    .links__button {
        font-size: 18px;
    }
}

@media (min-width: 1900px) {
    .content__container {
        max-width: 40%;
    }
}

@media (min-width: 2500px) {
    .content-section {
        width: 85%;
    }

    .content__container {
        max-width: 38%;
    }
}
