.hero-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100vh;
    width: 95%;
    margin: 0 auto;
    font-size: 12px;
    font-family: 'Source Code Pro', monospace;
}

.hero-header h1 {
    margin-bottom: 10vh;
}

.hero-header .caret {
    -webkit-animation: blink-caret 0.5s step-end infinite;
            animation: blink-caret 0.5s step-end infinite;
}

.sub-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 18px;
    font-family: 'Source Code Pro', monospace;
}

.sub-header .caret {
    display: none;
}

@-webkit-keyframes blink-caret {
    from, to { color: transparent }
    50% { color: #728080 }
}

@keyframes blink-caret {
    from, to { color: transparent }
    50% { color: #728080 }
}

@media (min-width: 410px) {
    .hero-header {
        font-size: 14px;
    }
}

@media (min-width: 750px) {
    .hero-header {
        font-size: 16px;
    }

    .sub-header {
        font-size: 20px;
    }
}

@media (min-width: 1020px) {
    .hero-header {
        font-size: 12px;
    }
}

@media (min-width: 1200px) {
    .hero-header h1 {
        white-space: nowrap;
    }
}

@media (min-width: 1400px) {
    .hero-header {
        font-size: 18px;
    }
}

@media (min-width: 2500px) {
    .hero-header {
        font-size: 30px;
    }

    .sub-header {
        font-size: 26px;
    }
}
