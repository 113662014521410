.contact-me {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px 0;
}

.contact-me__button {
    background-color: #2d7254;
    padding: 8px 0;
    color: white;
    font-family: 'Source Code Pro', monospace;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    width: 80%;
    text-align: center;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.contact-me__button:hover {
    -webkit-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
            transform: translateY(-5px);
    -webkit-transition: .1s;
    -o-transition: .1s;
    transition: .1s;
}

@media (min-width: 750px) {
    .contact-me {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        padding: 15px 0;
    }
    
    .contact-me__button {
        padding: 12px 0;
        font-size: 20px;
        width: 30%;
    }
}

@media (min-width: 1020px) {
    .contact-me {
        gap: 20px;
    }

    .contact-me__button {
        width: 22%;
        font-size: 18px;
    }
}

@media (min-width: 2500px) {
    .contact-me {
        gap: 40px;
        padding: 50px 0;
    }

    .contact-me__button {
        width: 18%;
        font-size: 22px;
    }
}
